<template >
  <v-theme-provider>
    <base-section id="payout-list" space="60" v-if="address != ''">
      <v-container>
        <v-row justify="center" class="mb-4">
          <v-col cols="12">
            <div class="text-h3 text-center">{{ $ml.get("div_history") }}</div>
          </v-col>
        </v-row>
        <!--выбор диапазона дат-->
        <v-row justify="center" class="mb-4">
          <v-col cols="10" sm="6" md="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="dateFrom"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label="From date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                @click:date="
                  $refs.menu1.save(dateFrom);
                  dateChanged();
                "
              >
                <!--<v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu1.save(dateFrom);
                    dateChanged();
                  "
                >
                  OK
                </v-btn>-->
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="10" sm="6" md="4">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="dateTo"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  label="To date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                @click:date="
                  $refs.menu2.save(dateTo);
                  dateChanged();
                "
              >
                <!--<v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu2.save(dateTo);
                    dateChanged();
                  "
                >
                  OK
                </v-btn>-->
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!--график выплат-->
        <v-row justify="center">
          <v-col cols="12" md="10">
            <LineChart
              v-if="loaded"
              :chartdata="chartdata"
              :options="options"
            />
          </v-col>
        </v-row>
        <!--таблица выплат-->
        <v-row justify="center" class="mt-8">
          <v-col cols="12" md="10">
            <v-simple-table class="elevation-5 rounded">
              <template v-slot:default>
                <thead>
                  <tr class="white--text primary">
                    <th
                      class="text-left font-weight-bold white--text"
                      style="border-radius: 5px 0 0 0"
                    >
                      {{ $ml.get("payout_date") }}
                    </th>
                    <th class="text-left white--text">{{ $ml.get("coin") }}</th>
                    <th class="text-left white--text">
                      {{ $ml.get("amount") }}
                    </th>
                    <th class="text-left white--text">
                      {{ $ml.get("course") }}
                    </th>
                    <th
                      class="text-left white--text"
                      style="border-radius: 0 5px 0 0"
                    >
                      {{ $ml.get("in_usdt") }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="payouts.length > 0">
                  <tr v-for="item in payouts" :key="item.name">
                    <td>
                      {{ new Date(item.timestamp).toLocaleString("ru") }}
                    </td>
                    <td>
                      {{
                        dictionary[item.assetId] != null
                          ? dictionary[item.assetId].name
                          : item.assetId
                      }}
                    </td>
                    <td>
                      {{
                        (
                          item.amount /
                          10 ** dictionary[item.assetId].decimals
                        ).toFixed(dictionary[item.assetId].decimals)
                      }}
                    </td>
                    <td>{{ item.course.toFixed(2) }}</td>
                    <td>{{ (item.inUsd / 10 ** 6).toFixed(6) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="font-weight-bold">Итого:</td>
                    <td class="font-weight-bold">
                      {{
                        payouts.length > 0
                          ? payouts
                              .map((x) => x.inUsd / 10 ** 6)
                              .reduce((a, b) => a + b)
                              .toFixed(6)
                          : 0
                      }}
                      USDT
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <!--<v-row justify="center">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="payouts"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:header.id="{ header }">
                {{ header.text.toUpperCase() }}
              </template>
              <template v-slot:item.id="{ item }">
                <div
                  @click="openLink(item.id)"
                  style="
                    display: inline-block;
                    padding: 4px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    background: #416fad;
                    color: white;
                    border-radius: 14px;
                    cursor: pointer;
                  "
                >
                  {{ item.id }}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>-->
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
import axios from "axios";
import LineChart from "../../components/LineChart.vue";
import { ebus } from "../../main.js";
var serverUrl = "https://syrf.info";

export default {
  name: "PayoutList",
  components: {
    LineChart,
  },
  data: () => ({
    dateFrom: new Date(2021, 0, 1, 12).toISOString().substr(0, 10),
    dateTo: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    assetInfo: null,
    address: "",
    amount: 0,
    amountInOrigin: 0,
    payouts: [],
    dictionary: {
      "474jTeYx2r2Va35794tCScAXWJG9hU2HcgxzMowaZUnu": {
        name: "ETH",
        decimals: 8,
      },
      "34N9YcEETLWn93qYQ64EsP1x89tSruJU44RrEMSXXEPJ": {
        name: "USDT",
        decimals: 6,
      },
      "DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p": {
        name: "USD-N",
        decimals: 6,
      },
      "8LQW8f7P5d5PZM7GtZEBgaqRPGSzS3DfPuiXrURJ4AJS":{
        name: "BTC",
        decimals: 8,         
      },
      "6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK":{
        name: "SYRF",
        decimals: 8,         
      },      
    },
    headers: [
      {
        text: "Дата выплаты",
        align: "start",
        sortable: true,
        value: "timestamp",
      },
      {
        text: "Id транзакции",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Комментарий",
        align: "start",
        sortable: false,
        value: "attachment",
      },
      {
        text: "Сумма в USDT",
        align: "start",
        sortable: true,
        value: "inUsd",
      },
    ],
    chartdata: {
      labels: [],
      datasets: [
        {
          label: "Начислено дивидендов, в USDT",
          backgroundColor: "#f87979A0",
          borderColor: "#f87979",
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxRotation: 0,
              autoSkipPadding: 15,
              //min: 0,
              //max: 6,
              //stepSize: 1,
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            ticks: {
              min: 0,
              // max: 1,
              //stepSize: 0.1,
            },
          },
        ],
      },
    },
    loaded: false,
  }),
  created() {
    ebus.$on("userauthorized", (addr) => {
      this.loaded = false;
      this.address = addr;
      this.getPayoutsByAddress();
    });
  },
  mounted() {},
  methods: {
    dateChanged() {
      this.loaded = false;
      this.getPayoutsByAddress();
      console.log("from", this.dateFrom);
      console.log("to", this.dateTo);
    },
    getAssetInfo() {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/details/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log("prices in usdt:", response.data);
          this.assetInfo = response.data;
        });
    },
    getAssetAmountInAddress(addr) {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/balance/" +
            addr +
            "/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log("asset amount in addr:", response.data.balance / 10 ** 8);
          this.amount = response.data.balance / 10 ** 8;
        });
    },
    getPayoutsByAddress() {
      axios
        .get(serverUrl + "/api/GetPayoutsByAddress?addr=" + this.address)
        .then((response) => {
          //
          var myDateFrom = this.dateFrom.split("-");
          var newDateFrom = new Date(
            myDateFrom[0],
            myDateFrom[1] - 1,
            myDateFrom[2]
          );
          var myDateTo = this.dateTo.split("-");
          var newDateTo = new Date(
            myDateTo[0],
            myDateTo[1] - 1,
            myDateTo[2],
            23
          );
          //фильтруем по времены выплаты
          var t = response.data.filter(
            (x) => x.timestamp >= newDateFrom && x.timestamp <= newDateTo
          );
          console.log("выплат по кошельку: ", t);

          this.payouts = [];
          this.payouts = t; //response.data;

          this.chartdata.labels = [];
          this.chartdata.labels = this.chartdata.labels.concat(
            t.map((x) => new Date(x.timestamp).toLocaleDateString("ru"))
          );

          this.chartdata.datasets[0].data = [];
          this.chartdata.datasets[0].data =
            this.chartdata.datasets[0].data.concat(
              t.map((x) => x.inUsd / 1000000)
            );

          for (let i = 1; i < this.chartdata.datasets[0].data.length; i++) {
            this.chartdata.datasets[0].data[i] =
              this.chartdata.datasets[0].data[i] +
              this.chartdata.datasets[0].data[i - 1];
          }

          this.loaded = true;
        });
    },
  },
  beforeDestroy() {
    ebus.$off("userauthorized");
  },
};
</script>

<style scoped>
.v-data-table__wrapper > table > tbody > tr:hover {
  background: #42a5f670 !important;
}
</style>

